
import { Link } from 'gatsby';
import {Helmet} from "react-helmet";
import Layout from "../../components/layout";
import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/src/styles.js';
import { Title, Twitter, Testonimal } from "../../components/Globals";
import { FaAngleDoubleDown } from "react-icons/fa";
import React, { useState } from 'react';
import Modal from 'react-modal';
import { BsFillPlusSquareFill,  } from "react-icons/bs";
import { AiFillMinusSquare } from "react-icons/ai";
import Certificate from '../../certificate/Sample-certificate.png';

const SalesforceCertificationPage = () => {

    const [course1, setCourse1] = React.useState(false);
    const [course2, setCourse2] = React.useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

    const ref = React.createRef();
    const [ExploreJobs] = React.useState([

        {
          id: 1,
          title: 'SEP 18, 2021',
          desc: '10.00 AM',
        },
        {
          id: 2,
          title: 'SEP 25, 2021',
          desc: '10.00 AM'
        },
        {
          id: 3,
          title: 'OCT 15, 2021',
          desc: '10.00 AM'
        },
        
      ]);
    
   
      const handleSubmit = (event) => {
        event.preventDefault();
        let headers = new Headers();
        headers.append('Authorization', 'Basic ' + btoa('api' + ":" + '5d2220e3b61e542f83de699a4ece81ad-4d640632-9b883e3e'));
        let formData = new FormData();
        formData.append('from', event.target.email.value);
        formData.append('to','trainings@cymetrixsoft.com');
        formData.append('subject', event.target.name.value+' - from Salesforce Certification Page');
        formData.append('text', 'Name :'+event.target.name.value);
        formData.append('text', 'Contact :'+event.target.contact.value);
        formData.append('text','Message :'+event.target.message.value);
       
        fetch('https://api.mailgun.net/v3/sandbox0f7d801b76fb45bba72ceff5856c284d.mailgun.org/messages', {
          method: 'post',
          headers: headers,
          body: formData,
        })
        .then(function (data) {
          if(data.status === 200) {
            //alert('Message sent successfuly');
            window.location = 'https://www.cymetrixsoft.com/Thankyou/';
          }
        })
        .catch(function (error) {
          console.log('Request failed', error);
        });
      }
      

    return (
      <Layout>
        
        <div className="salesforcecertification-background h-screen flex flex-col w-full overflow-x-hidden">
          <Helmet>
            <title>Salesforce Certification Training | Cymetrix Software</title>
            <meta name="description" content="Cymetrix SALESFORCE PARTNERS INDIA delivers salesforce training where we teach from basics to the master level of salesforce admin and development."/>
            <meta name="keywords" content="cymetrix,Salesforce certification training, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
            <link rel="canonical" href="https://www.cymetrixsoft.com/services/salesforce-certification-training/" />
          </Helmet>
         
         
        </div>
         <div className="flex flex-col items-center md:items-stretch md:flex-row flex-wrap max-w-screen-xl sma:py-1 py-10 md:py-14">
                <div className=" sma:px-3 md:w-1/2 lg:w-2/3 px-6 flex md:mb-10 w-full">
                <div className="sma:mt-3 flex flex-col max-w-2xl items-center mt-16"></div>
        
          <div className="relative max-w-3xl mx-auto mt-10 mb-10 sma:mx-3 sma:w-full " style={{backgroundColor: '#f6f6f6'}} ref={ref}>
            <div className="text-gray-700 pb-5">
                <h1 className="text-lg  px-5 font-bold uppercase mt-5 mb-3" style={{color: '#1e407b'}}>About Training</h1>
                
                <p className="mt-4 px-5 text-justify">This Training program is a joint venture of Cymetrix, Salesforce certified Partner <Link to="https://cymetrixsoft.com" className="underline">(www.cymetrixsoft.com)</Link> and St Vincent Pallotti College <Link to="https://www.stvincentngp.edu.in" className="underline">(www.stvincentngp.edu.in)</Link>. </p>

                <p className="mt-4 px-5 text-justify">Our Salesforce certification training classes will enable you to clear Certification program from Salesforce like Salesforce Certified Administrator and Salesforce Certified Platform Developer1 ( PD1), which will give you a boost in your career. You will master skills, such as the customization capabilities of this platform, managing users, building applications with Salesforce Lightning, and more. Further, in this Salesforce certification training course, you will master SFDC, SFDC Data Model, App Builder, Apex, Visualforce, etc.</p>

                <p className="mt-4 px-5 text-justify">Throughout the training, senior Salesforce developers will provide you with not only certification but also hands-on development expertise</p>
                

                <h1 className="text-lg  px-5 font-bold uppercase my-3" style={{color: '#1e407b'}}>Location</h1>

                <ul className="ml-1 text-base list-disc list-inside leading-relaxed px-4 my-4 ">
                  <li> Online </li>
                  <li>In Person (Post Pandemic) : </li> 
                  <li className="list-disc list-inside leading-relaxed px-4 my-2"> St Vincent Pallotti College, Nagpur campus </li>
                  <li className="list-disc list-inside leading-relaxed px-4">Cymetrix Software Office in Andheri East Mumbai (If you are not able to attend from Nagpur campus)</li> 
                </ul>
                
                <h1 className="text-lg  px-5 font-bold uppercase my-3" style={{color: '#1e407b'}}>Fees</h1> 
 <div>
    <table>
  <thead>
    <tr>
      <th>Course Name</th>
      <th>Total Fees To Be Paid By Participant (INR)</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Salesforce Administrator</td>
      <td>20000</td>
    </tr>
    <tr className="bg-emerald-200">
      <td>Salesforce Developer</td>
      <td>20000</td>
    </tr>
    <tr className="bg-emerald-200">
      <td>Registered for both the courses</td>
      <td>30000</td>
    </tr>
    
  </tbody>
</table>
</div>
<h1 className="text-lg mt-5 px-5 font-bold uppercase my-3" style={{color: '#1e407b'}}>Payment </h1> 
<div className="mx-10 border border-gray-700">
 <p className="mt-4 px-5 text-justify"> <b>Beneficiary Name: </b> Cymetrix Software Private Limited  </p>
 <p className="mt-4 px-5 text-justify"> <b>Bank: </b> HDFC Bank  </p>
 <p className="mt-4 px-5 text-justify"> <b>Account No.: </b> 502000 13622 542  </p>
 <p className="mt-4 px-5 text-justify"> <b>Bank Branch: </b> Plot 265/2, Baner Road Opp Bharat Gas Petrol Pump, Baner Pune-411045  </p>
 <p className="mt-4 px-5 text-justify"> <b>IFSC Code: </b> HDFC0001794</p>
 </div>
            </div>
          </div>
        </div>



<div className="sma:px-3 md:w-1/2 lg:w-1/3 px-6 flex ">
                            <div className="sma:mt-3 flex flex-col mx-auto max-w-md items-center mt-12 mr-20 ">
                            <h1 className="text-lg font-bold ml-3">ENQUIRY</h1>
                                <div className="py-4 border  border-gray-300 shadow-xl">
                                
                                <form className="justify-center flex" onSubmit={handleSubmit} >
                                        <div className="bg-white rounded lg:px-8 px-5 pt-6 pb-8 mb-4 flex flex-col max-w-6xl sm:w-10/12">
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full">
                                                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="name">
                                                    Enter Name*
                                                </label>
                                                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                                                        id="name" type="text" placeholder="" name="name" />
                                                </div>
                                            </div>
                                           
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full">
                                                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="email">
                                                    Enter Email*
                                                </label>
                                                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                                                        id="email" type="text" placeholder="" name="email" />
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full">
                                                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="contact">
                                                    Enter Contact Number*
                                                </label>
                                                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                                                        id="contact" type="text" placeholder="" name="contact" />
                                                </div>
                                            </div>
                                           
                                      
                                            
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full">
                                                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="message">
                                                    Enter Your Message*
                                                </label>
                                                <textarea className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3 h-48"
                                                            id="message" type="text" placeholder="" name="message" >
                                                </textarea>
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mt-2">
                                                <div className="sma:w-full">
                                                <button
                  className="sma:w-full bg-header text-white font-bold py-2 px-4 border-b-4 hover:border-b-#263f5f2 border-gray-500 hover:border-gray-100 uppercase rounded-full" type="submit">
                  Send Message
                </button>
                                          </div>      </div>
                                          <h1 className="text-lg font-bold mt-5  text-center">For Immediate Inquiry</h1>
                                          <h3 className="text-lg font-bold mt-2 text-center">Call us at : </h3>
                                          <h1 className="text-xl font-bold mt-2 ml-3 text-blue-600 text-center"> +91 8454091419 / +91 8308831248 </h1>
                                         
                                            </div>
                                     
                                        </form>
                                </div>
                            </div>
                        </div>
                        </div>
        <div className="job-background w-full flex flex-col items-center lg:mb-8 sma:w-full">
          <div className="max-w-6xl mx-4 sma:mb-4">
              <Title title="Upcoming Training Dates" classValue={'text-white sma:text-xl text-4xl'} />
              <div className="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-4 md:py-14">
                {
                  ExploreJobs.map(job => {
                      return <div className="mt-4 md:w-1/2 lg:w-1/3 sma:w-full text-red-700  " key={job.id}>
                         
                          
                          <div className="bg-white p-10 h-full border-b-4 border-pink-500 rounded-lg hover:border-hover flex flex-col items-center sm:mx-2 sm:p-3 md:p-8 w-64 ml-16 ">
                          
                              <h1 className="text-2xl mt-5 text-center">{job.title}</h1>
                              <p className="mt-5 text-sm text-gray-600 text-center">{job.desc}</p>
                          </div></div>
                         
                  })
                }
              </div>

              <h1 className="text-lg  px-5 font-bold uppercase my-3" style={{color: '#FFFFFF'}}>Note</h1>
              <ul className="ml-1 text-base list-disc list-inside leading-relaxed px-4 my-4 text-white">
                  <li>Certification exam fees need to be separately paid by participants to Salesforce. </li>
                  <li>Presently all the course are online due to Pandemic. </li>
                  <li>Taxes will be charged extra as applicable.</li>
                  </ul>
          </div>
        </div>

        <div className="bg-gray-300 mx-20 px-5 py-8">
                                <h1 className="text-2xl font-extrabold text-left underline">Salesforce Certification Training Course Overview</h1>
                               
                                <h1 className="text-lg font-bold mt-5 ml-3">Salesforce Admin</h1>
                                <div className="flex">
                                    {
                                        !course1 ? <BsFillPlusSquareFill className="h-6 w-6 mt-5" onClick={() => setCourse1(true)}/> : <AiFillMinusSquare className="h-8 w-8 mt-4" onClick={() => setCourse1(false)}/>
                                    }
                                    <h1 className="text-lg font-bold mt-5 ml-3">Topics</h1>
                                </div>
                                {
                                course1 ? <div className="">
                                <ul className="list-decimal text-base list-inside leading-8 px-3">
                                    <li>Fundamentals of Cloud Computing</li>
                                    <li>Customer Relationship Management Basics </li>
                                    <li>Salesforce Classic Vs Lightning Experience </li>
                                    <li>Standard and Custom Objects </li>
                                    <li>Datatypes</li>
                                    <li>Relational Data Modelling </li>
                                    <li>Schema Builder App </li>
                                    <li>Manager & Tabs </li>
                                    <li>Customizing Page Layouts using Record Types</li>
                                    <li>Mini & Compact Page Layouts </li>
                                    <li>App Builder </li>
                                    <li>List Views & Related Lists </li>
                                    <li>Formula field </li>
                                    <li>Data Aggregation using Roll-up Summaries</li>
                                    <li>Validation Rules</li>
                                    <li>Duplicate Rules </li>
                                    <li>Workflow Rules</li>
                                    <li>Process Builder </li>
                                    <li>Profiles & Permission Sets</li>
                                    <li>Enforcing Field Level Security </li>
                                    <li>User Management  </li>
                                    <li>OWD & Sharing Settings</li>
                                    <li>Record Sharing </li>
                                    <li>Data Loader  </li>
                                    <li>Import Wizard  </li>
                                    <li>Export Wizard </li>
                                 
                                </ul>
                            </div>:null
}
                <h1 className="text-lg font-bold mt-5 ml-3 mx-20 p-6 border-2  border-black">Course Coaching duration : 10 days OR 20 Hours</h1>

                <h1 className="text-lg font-bold mt-5 ml-3 mx-20 p-6 border-2 border-black">Hands on Activities duration : 5 days OR 40 Hours</h1>
</div>

    <div className="bg-gray-300 mx-20 px-5 mt-20 mb-20 py-8">
                                <h1 className="text-lg font-bold mt-5 ml-3">Salesforce Developer</h1>
                                <div className="flex">
                                {
                                        !course2 ? <BsFillPlusSquareFill className="h-6 w-6 mt-5" onClick={() => setCourse2(true)}/> : <AiFillMinusSquare className="h-8 w-8 mt-4" onClick={() => setCourse2(false)}/>
                                    }
                                    <h1 className="text-lg font-bold mt-5 ml-3">Topics</h1>
                                </div>  
                                {
                                    course2 ? <div className="">
                                <ul className="list-decimal text-base list-inside leading-8 px-3">
                                    <li>CRM for Salesforce Classic </li>
                                    <li>Salesforce Platform Basics </li>
                                    <li>Leads and Opportunities </li>
                                    <li>Product and price book </li>
                                    <li>Data Modeling , Record Type, Page Layout </li>
                                    <li>Data Security  </li>
                                    <li>Data Management  </li>
                                    <li>Formula and Validation Rules </li>
                                    <li>Salesforce Automation Workflow </li>
                                    <li>Salesforce Automation Approval Process  </li>
                                    <li>Salesforce Lightning Process Builder  </li>
                                    <li>Reports and Dashboards  </li>
                                    <li>Database (DML,SOQL,SOSL)  </li>
                                    <li>Apex</li>
                                    <li>Visual force (Custom Controllers, List Controllers, Controller Extensions) </li>
                                    <li>Apex Trigger </li>
                                    <li>Asynchronous Apex </li>
                                    <li>Apex Testing </li>
                                    <li>Integration Basics </li>
                                    <li>Change Management</li>
                                </ul>
                               </div>:null
}
                                <h1 className="text-lg font-bold mt-5 ml-3 mx-20 p-6 border-2  border-black">Course Coaching duration : 10 days OR 20 Hours</h1>

                                <h1 className="text-lg font-bold mt-5 ml-3 mx-20 p-6 border-2 border-black">Hands on Activities duration : 5 days OR 40 Hours</h1>
                                <div className=" p-8 flex flex-col items-center sm:mx-2 sm:p-3 md:p-6"><a href={Certificate} download="Sample_Certificate.png" className="uppercase font-bold px-8 lg:px-10 py-3 rounded bg-gray-500 text-gray-100 hover:bg-hover focus:shadow-outline focus:outline-none transition duration-300"> Download Sample Certificate </a></div>



                     </div> 
                        
                     
      </Layout>
    );
    }


export default SalesforceCertificationPage;